<template>
  <h1 v-if="h1"
      :class="[marginBottom, textColor, fontSize]"
      class="font-bold md:w-full"
  >
    <slot />
  </h1>

  <h2 v-else
      :class="[marginBottom, textColor, fontSize]"
      class="font-bold md:w-full"
  >
    <slot />
  </h2>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'MARSTitle',
  props: {
    h1: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: String,
      default: 'mb-12'
    },
    textColor: {
      type: String,
      default: 'text-standard'
    },
    fontSize: {
      type: String,
      default: 'md:text-45xl lg:text-45xl laptop:text-55xl',
    }
  }
})
</script>

<style scoped>

</style>
